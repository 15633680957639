import * as React from 'react'

import { cn } from '~/lib/utils'

export interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {}

const Input = React.forwardRef<HTMLInputElement, InputProps & { withDefaultStyle?: boolean }>(
    ({ className, type, withDefaultStyle = true, ...props }, ref) => {
        return (
            <input
                type={type}
                className={
                    withDefaultStyle
                        ? cn(
                              'flex h-10 w-full rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium file:text-foreground placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50',
                              className
                          )
                        : cn(className)
                }
                ref={ref}
                {...props}
            />
        )
    }
)
Input.displayName = 'Input'

interface InputWithIconProps extends InputProps {
    iconsBefore?: React.ReactNode[]
    iconsAfter?: React.ReactNode[]
}

const InputWithIcon = React.forwardRef<HTMLInputElement, InputWithIconProps>(
    ({ iconsBefore = [], iconsAfter = [], className, ...props }, ref) => {
        return (
            <div
                className={cn(
                    'flex h-10 w-full rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium file:text-foreground placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50',
                    className
                )}>
                {iconsBefore.length > 0 && (
                    <div className="flex items-center gap-2">
                        {iconsBefore.map((icon, index) => (
                            <span key={`icon-before-${index}`} className="flex items-center justify-center">
                                {icon}
                            </span>
                        ))}
                    </div>
                )}
                <Input
                    ref={ref}
                    withDefaultStyle={false}
                    className="flex-grow flex-shrink bg-transparent text-sm focus-visible:outline-none disabled:cursor-not-allowed disabled:opacity-50"
                    {...props}
                />
                {iconsAfter.length > 0 && (
                    <div className="flex items-center gap-2">
                        {iconsAfter.map((icon, index) => (
                            <span key={`icon-after-${index}`} className="flex items-center justify-center">
                                {icon}
                            </span>
                        ))}
                    </div>
                )}
            </div>
        )
    }
)

InputWithIcon.displayName = 'InputWithIcon'

export { Input, InputWithIcon }
