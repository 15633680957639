import { BusinessAccessT } from '~/types'

export const BusinessCategory = [
    {
        key: 'CAT01',
        id: 'Minimarket & Sembako',
        en: 'Minimarket',
        vi: 'Siêu thị & Thực phẩm',
        ms: 'Minimarket',
        th: 'ร้านค้าขนาดย่อม & อาหารสด',
        tl: 'Minimarket at Grocery'
    },
    {
        key: 'CAT02',
        id: 'Pakaian, Bahan Pakaian & Aksesoris',
        en: 'Clothing & Accessories',
        vi: 'Quần áo, Vải & Phụ kiện',
        ms: 'Pakaian, Bahan Pakaian & Aksesoris',
        th: 'เสื้อผ้า, ผ้า & อุปกรณ์',
        tl: 'Clothing & Accessories'
    },
    {
        key: 'CAT03',
        id: 'Kecantikan, Kesehatan & Perawatan',
        en: 'Beauty, Health & Care',
        vi: 'Sắc đẹp, Sức khỏe & Chăm sóc',
        ms: 'Kecantikan, Kesehatan & Perawatan',
        th: 'สุขภาพ, สุขภาพ & การดูแล',
        tl: 'Beauty, Health & Care'
    },
    {
        key: 'CAT04',
        id: 'Elektronik, HP & Aksesoris HP',
        en: 'Electronics, Handphone & Accessories',
        vi: 'Điện tử, Điện thoại & Phụ kiện',
        ms: 'Elektronik, HP & Aksesori HP',
        th: 'อิเล็กทรอนิกส์, โทรศัทพ์ & อุปกรณ์ต่อเติม',
        tl: 'Electronics, Handphone & Accessories'
    },
    {
        key: 'CAT05',
        id: 'Makanan & Minuman',
        en: 'Food & Beverages',
        vi: 'Thức ăn & Đồ uống',
        ms: 'Makanan & Minuman',
        th: 'อาหาร & เครื่องดื่ม',
        tl: 'Food & Beverages'
    },
    {
        key: 'CAT06',
        id: 'Buah, Sayur, Daging & Makanan Mentah',
        en: 'Fresh Fruits, Vegetables, Meat & Raw Food',
        vi: 'Trái cây, rau, thịt & Thực phẩm tươi',
        ms: 'Buah, Sayur, Daging & Makanan Mentah',
        th: 'ผลไม้, ผัก, เนื้อ & อาหารสด',
        tl: 'Fresh Fruits, Vegetables, Meat & Raw Food'
    },
    {
        key: 'CAT07',
        id: 'Alat Kantor & Buku',
        en: 'Office Tools & Book',
        vi: 'Dụng cụ văn phòng & Sách',
        ms: 'Alat Kantor & Buku',
        th: 'เครื่องมือสำนักงาน & หนังสือ',
        tl: 'Office Tools & Book'
    },
    {
        key: 'CAT08',
        id: 'Onderdil Otomotif',
        en: 'Automotive Parts',
        vi: 'Phụ tùng ô tô',
        ms: 'Onderdil Otomotif',
        th: 'อุปกรณ์รถยนต์',
        tl: 'Automotive Parts'
    },
    {
        key: 'CAT09',
        id: 'Alat Material Rumah',
        en: 'Home Tools & Materials',
        vi: 'Dụng cụ nhà & Vật liệu',
        ms: 'Alat Material Rumah',
        th: 'เครื่องมือบ้าน & วัสดุ',
        tl: 'Home Tools & Materials'
    },
    {
        key: 'CAT10',
        id: 'Pertanian, Peternakan & Perikanan',
        en: 'Agriculture, Farm & Fishery',
        vi: 'Nông nghiệp, Chăn nuôi & Thủy sản',
        ms: 'Pertanian, Peternakan & Perikanan',
        th: 'การเกษตร, การเลี้ยงสัตว์ & การทำนา',
        tl: 'Agriculture, Farm & Fishery'
    },
    {
        key: 'CAT11',
        id: 'Perhiasan',
        en: 'Jewelry',
        vi: 'Trang sức',
        ms: 'Perhiasan',
        th: 'สร้างสรรค์',
        tl: 'Jewelry'
    },
    {
        key: 'CAT00',
        id: 'Lainnya',
        en: 'Others',
        vi: 'Khác',
        ms: 'Lainnya',
        th: 'อื่น ๆ',
        tl: 'Others'
    }
]

export const allAccess: BusinessAccessT[] = [
    'stockIn',
    'stockOut',
    'stockAudit',
    'addProduct',
    'editProduct',
    'deleteProduct',
    'readCapitalPrice',
    'addCategory',
    'editCategory',
    'deleteCategory',
    'addStaff',
    'removeStaff',
    'removeStaff',
    'changeBusinessProfile',
    'addBusinessCustomer',
    'editBusinessCustomer',
    'removeBusinessCustomer',
    'addBusinessSupplier',
    'editBusinessSupplier',
    'removeBusinessSupplier',
    'readAccountingReport'
]
